@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto';
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li {
  font-family: 'Roboto-thin';
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.active-indicator {
  position: relative;
}
.active-indicator::after {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: white 2px solid;
  border-radius: 50%;
}

@font-face {
  font-family: 'Roboto-thin';
  src: url('./fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto-medium';
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Roboto-bold';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Dubai-Light';
  src: url('./fonts/Dubai-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Dubai-Regular';
  src: url('./fonts/Dubai-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Dubai-Medium';
  src: url('./fonts/Dubai-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Dubai-Bold';
  src: url('./fonts/Dubai-Bold.ttf') format('truetype');
  font-weight: 700;
}
